
import * as React from 'react';
import { Container } from 'react-bootstrap';
import { Card } from "react-bootstrap";

export class LovePage extends React.Component {
    render() {
        return (
            <Container className="p-3">
                <h2 style={{textAlign: "center"}}>
                You are special and unique. I can't think of finding anyone quite like you in the world.
                </h2>
                <Card>
                    <Card body>
                        <p>
                            No one has the same energy as you. You're usually ridiculous and a little weird and I absolutely love it.
                            Being weird is not a negative thing, it just means you don't easily fit into normal definitions of people that society has made.
                            It means you're special and unique and impossible to simply qualify as a couple of words.
                            You're full of shenanigans and the most interesting person I know. Every moment of our time together is interesting and fun.
                            No matter what we do there is always something fun about it, whether it's little dances or cooking together, or just walking around.
                            You are an amazing woman full of life and energy and I love every moment we spend together.
                        </p>
                    </Card>
                </Card>
                <br/>
                <h2 style={{textAlign: "center"}}>
                    I love and cherish you more than anything in the world. I've compiled a list of some messages I've sent previously:
                </h2>
                <Card>
                    <Card body>
                        Good Morning! I had a fantastic lazy day yesterday. We hit all the important parts: food, adventure time, and of course at least four hours of cuddling. I just feel like I found my spot in the world and I know what I'm good for when I'm holding you. It feels like I've made the right choice in my life and I'm happy and fulfilled. I love it and I love you.
                    </Card>
                </Card>
                <br/>
                <Card>
                    <Card body>
                        I love Elise more than anything. She’s the light in my world. If she’s unhappy I’m anxious and happy. If she’s happy I’m happy. I’m so invested in Elise that my emotions are irrevocably tied to hers. I’m dedicated to her and being with her, dedicated to our relationship and growth. I have put everything I have into being with her. I’ve gone through more emotion, good and bad, with her than with anyone or anything else. Nothing has moved me and changed me and caused me to grow as Elise has. I want to be what makes her happy. I want to be what can give her stability, I want to be her partner for life. I want these things and have fought for them repeatedly. I’ve fought to be more active, more aware, more understanding. I’ve fought many of those battles with myself and my actions, and have won more and more. I’ve gotten so much better and understanding her emotions and working to make her happier when she’s hurt, to make her more secure when she’s anxious. I’ve put my heart and soul into Elise, whether we’re happy or not. I’ve caused almost all of the bad times in our relationship and I deeply regret that. I always fight to be better afterward, I always double down on putting in the effort, and I always do better. Each time I hurt Elise is a time where I hurt myself. Each time I hurt Elise is a time where I’ve destabilized my entire life goal. Each hurt is a brick taken away from the stability of our relationship and our happiness. I’ll always work to add more bricks and more stability than I can ever take away with my actions. 
                    </Card>
                </Card>
                <br/>
                <Card>
                    <Card body>
                        I consider you the prettiest little thing on earth and I consider you the best thing that's ever happened to me
                    </Card>
                </Card>
                <br/>
                <Card>
                    <Card body>
                        I love how proud I can be of you. Anyone sees us on the street? They know I'm with a lil cutie. Introduce to friends? They  see a cutie who's tons of fun. Killing it on the hiking game. Introduce to family? She's already killing it on the career game. You've said you're a catch and I 100% agree.
                        I love your smile and how full of fun and energ you are. I love the insane level of shenanigans you're capable of putting out, even outdoing any level I can produce. I love your goofy dances and our little dance parties.
                    </Card>
                </Card>
                <br/>
                <Card>
                    <Card body>
                        You are so much more than that to me. I love everything about you. I love your shenanigans and your smile. I love how you dance with me (poorly) for no reason. I love how you hold me when we hug. I love how smart and driven and motivated you are. I love how we could become a power couple energy. I'm so proud of you and the success you've already had in your small career It's something I want to show off because look! My beautiful girlfriend is cool and successful too!
                    </Card>
                </Card>
                <br/>
                <Card>
                    <Card body>
                        The mood of the evening was very cozy and kind of domestic. I mean domestic in a very wholesome way though. We had a very familiar and connected energy going. I'm not sure if that makes sense but we were just close and our conversation was always fun and felt right. We had a good dinner together and ended up just talking and hanging out and it felt so peaceful and right. A key moment for me was when you said "our home". I've never really thought of either apartment as "our home" before. Like it's always been my apartment and your apartment and we just sorta visit at the other. All this talk and the planning and concrete steps we've taken for a apartment that was just us has made me so hopeful and excited for the future in a way I really haven't been in a long time. An apartment for me has never had so much importance and excitement to me, they've mostly just been out of convenience for work or a place to exist in. This will be a home together. And you subconsciously had the same feeling about how we were together recently. You called it home and that feeling is something I've gotten so excited for. A new home that's less temporary rooming situations that are necessary to a more permanent place with a "roommate" I love and want to spend my life with. A lot of warm fuzzy feelings from that. The sort of connection you have with a life partner, then we just sorta cuddled and held each other without talking for a while and it just felt like the right thing to do
                    </Card>
                </Card>
                <br/>
                <Card>
                    <Card body>
                        I know you're going through trying times right now but you're stronger than what you're going through. You're going to rise above eugene while he simmers in his own mediocrity. You're strong, beautiful, and intelligent and I love you more than anything. Goodnight lil bee
                    </Card>
                </Card>
                <br/>
                <Card>
                    <Card body>
                        Hello! I just wanted to stop by and say that I love you. I had a quality time this weekend and I'm exceedingly happy that I have you in my life. This week is Scout Week so I'm banking on getting you the highest quality stolen snaccs YET. If not I plan to quit and move to a better company, so I can properly express my love via stolen food. Thanks for being a cute lil bee.
                    </Card>
                </Card>
                <br/>
                <Card>
                    <Card body>
                        I'm sorry you're going through this mess. You're an amazing woman and I'm happy to have you in my life. You're smart and capable and you'll get through this because you can do anything. I love you and believe in you
                    </Card>
                </Card>
            </Container>
        )
    }
}