import gremlin from '../photos/home/gremlin.jpg'
import bee from '../photos/home/bee.jpg'
import blep from '../photos/home/blep.jpg'
import hearts from '../photos/home/hearts.jpg'
import present from '../photos/home/present.jpg'
import selfie4 from '../photos/home/selfie4.jpg'
import spooky_bee from '../photos/home/spooky_bee.jpg'
import streamer_bee from '../photos/home/streamer_bee.png'

export const home_photos = [
    {
        src: gremlin,
        width: 2,
        height: 4,
        key: 'gremlin'
    },
    {
        src: bee,
        width: 3,
        height: 4,
        key: 'bee'
    },
    {
        src: blep,
        width: 4,
        height: 2,
        key: 'blep'
    },
    {
        src: hearts,
        width: 2,
        height: 4,
        key: 'hearts'
    },
    {
        src: present,
        width: 2,
        height: 4,
        key: 'present'
    },
    {
        src: selfie4,
        width: 2,
        height: 4,
        key: 'selfie4'
    },
    {
        src: spooky_bee,
        width: 2,
        height: 4,
        key: 'spooky_bee'
    },
    {
        src: streamer_bee,
        width: 2,
        height: 4,
        key: 'streamer_bee'
    },
]