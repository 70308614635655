
import * as React from 'react';
import Gallery from "react-photo-gallery";
import Container from 'react-bootstrap/Container';
import Carousel, { Modal, ModalGateway } from "react-images";

import { cute_photos } from "./cute_photos";
import { meme_photos } from "./meme_photos";
import { home_photos } from "./home_photos";

interface GalleryState {
    currentImage: number,
    viewerIsOpen: boolean
}

interface GalleryProps {
    tab: string
}

interface Photo {
    src: any,
    width: number,
    height: number,
    key: string
}

export class GalleryHolder extends React.Component<GalleryProps, GalleryState> {
    constructor(props: GalleryProps) {
        super(props);
        this.state = {
            currentImage: 0,
            viewerIsOpen: false
        };
    }

    setCurrentImage(curImage: number) {
        this.setState({
            currentImage: curImage
        });
    }

    setViewerIsOpen(isOpen: boolean) {
        this.setState({
            viewerIsOpen: isOpen
        });
    }

    openLightbox(event: any, { index }: { index: number }) {
        console.log("calling sets", index)
        this.setCurrentImage(index);
        this.setViewerIsOpen(true);
    }

    closeLightbox() {
        console.log("calling sets")
        this.setCurrentImage(0);
        this.setViewerIsOpen(false);
    }

    renderGallery(photos: any) {
        const { currentImage, viewerIsOpen } = this.state;
        return (
            <div>
                <Gallery photos={photos} onClick={(event, other) => this.openLightbox(event, other)} />
                <ModalGateway>
                    {viewerIsOpen ? (
                    <Modal onClose={() => this.closeLightbox()}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map((photo: any) => ({
                                ...photo,
                                srcset: photo.srcSet,
                                caption: photo.title
                            }))}
                        />
                    </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        )
    }

    render() {
        const { tab } = this.props
        var gallery;
        switch (tab) {
            case 'cute':
                gallery = this.renderGallery(cute_photos);
                break;
            case 'meme':
                gallery = this.renderGallery(meme_photos);
                break;
            case 'home':
                gallery = this.renderGallery(home_photos);
                break;
            default:
                gallery = <div>Nothing Found?!?!</div>
                break;
        }
        return (
            <Container className="p-3">
                {gallery}
            </Container>
        )
    }
}