
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { GalleryHolder } from "./GalleryHolder";
import ReactPlayer from 'react-player/lazy'

import bee_bird from '../photos/home/bee_bird.mp4'
import nala_dancing from '../photos/home/nala_dancing.mp4'

export class HomePage extends React.Component {
    render() {
        return (
            <Container className="p-3">
                <h2>
                    Welcome to the all new Bandito Bee collection! Now displaying
                    only the finest memes and cute pictures from the Bandito Bee chat!
                </h2>
                <p>
                    Here at Bandito Bee Museum we are proud of our collection and, of course,
                    of the Bandito Bee herself! This is a tribute to the fantastic ability she
                    has to create and distribute not only the finest memes, but also the cutest
                    content. Please view our tabs to see each carefully curated collection!
                    Here's a sneak peak:
                </p>
                <GalleryHolder tab='home' />
                <Row>
                    <Col>
                        <ReactPlayer
                            url={bee_bird}
                            playing={true}
                            loop={true}
                            controls={true}
                            muted={true}
                            volume={0}
                            width={405}
                            height={720}
                        />
                    </Col>
                    <Col>
                        <ReactPlayer
                            url={nala_dancing}
                            playing={true}
                            loop={true}
                            controls={true}
                            muted={true}
                            volume={0}
                            width={405}
                            height={720}
                        />
                    </Col>
                </Row>

            </Container>
        )
    }
}