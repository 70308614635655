import React from 'react';
import './App.css';
import { GalleryHolder } from "./components/GalleryHolder";
import { Container, Nav, Navbar } from "react-bootstrap"
import { Switch, Route } from 'react-router-dom';
import { HomePage } from './components/HomePage'
import { FuturePage } from './components/FuturePage'
import { LovePage } from './components/LovePage'

interface AppState {
    tab: string
}

export default class App extends React.Component<any, AppState> {
    constructor(props: any) {
        super(props)

        this.state = {
            tab: 'cute'
        }
    }
    render() {
        return (
            <Container>
                <Navbar sticky="top" fixed="top" bg="light" expand="lg">
                    <Navbar.Brand href="/">Bandito Bee!</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/meme">The Memes</Nav.Link>
                            <Nav.Link href="/cute">The Cutie</Nav.Link>
                            <Nav.Link href="/future">The Future!</Nav.Link>
                            <Nav.Link href="/love">Love</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div>
                    <Switch>
                        <Route exact path='/' component={HomePage} />
                        <Route exact path='/meme' component={MemeGallery} />
                        <Route exact path='/cute' component={CuteGallery} />
                        <Route exact path='/future' component={FuturePage} />
                        <Route exact path='/love' component={LovePage} />
                        <Route render={function () {
                            return <p>Not found</p>
                        }} />
                    </Switch>
                </div>
            </Container>
        )
    }
}

const CuteGallery = () => {
    return (
        <GalleryHolder tab='cute' />
    )
}
const MemeGallery = () => {
    return (
        <GalleryHolder tab='meme' />
    )
}
