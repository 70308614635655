
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import future from '../photos/future/future.jpg'
import apartment from '../photos/future/apartment.jpg'
import cozy_vibes from '../photos/future/cozy_vibes.png'
import flowers from '../photos/future/flowers.jpg'
import smiley_board from '../photos/future/smiley_board.jpg'
import pancakes from '../photos/future/pancakes.jpg'

const maxWidthStyle = {
    maxWidth: '80%'
}

export class FuturePage extends React.Component {
    render() {
        return (
            <Container className="p-3">
                <h2 style={{textAlign: "center"}}>
                    We have an exciting future together! Here are some fun previews:
                </h2>
                <Row>
                    <Col md>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <p style={{textAlign: "center"}}>
                            You may be a present, but you're my future. <br/>
                            I can't wait for a long and exciting future together. <br/>
                            I can't wait for a new apartment, new pets, new memories and adventures together. <br/>
                            I can't wait for tiny cabins and saturday pancakes. <br/>
                            For travelling with you. <br/>
                            For living with you. <br/>
                            For starting a little pet family with you. <br/>
                            <strong>For spending the rest of my life with you.</strong>
                        </p>
                    </Col>
                    <Col md>
                        <img src={future} alt='future'
                            style={maxWidthStyle}
                        />
                    </Col>
                </Row>
                         <br/>
                 <Row>
                     <Col md>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <p style={{textAlign: "center"}}>
                            Spending cozy days playing games on our battlestations.
                         </p>
                     </Col>
                     <Col md>
                         <img src={apartment} alt='apartment'
                             style={maxWidthStyle}
                         />
                     </Col>
                 </Row>
                         <br/>
                 <Row>
                     <Col md>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <p style={{textAlign: "center"}}>
                             Spending even cozier days with pets and plants.
                         </p>
                     </Col>
                     <Col md>
                         <img src={cozy_vibes} alt='cozy_vibes'
                             style={maxWidthStyle}
                         />
                     </Col>
                 </Row>
                         <br/>
                 <Row>
                     <Col md>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <p style={{textAlign: "center"}}>
                             Our beautiful charcuterie boards.
                         </p>
                     </Col>
                     <Col md>
                         <img src={smiley_board} alt='smiley_board'
                             style={maxWidthStyle}
                         />
                     </Col>
                 </Row>
                     <br/>
                 <Row>
                     <Col md>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <p style={{textAlign: "center"}}>
                             Our even more beautiful pancake tradition.
                         </p>
                     </Col>
                     <Col md>
                         <img src={pancakes} alt='pancakes'
                             style={maxWidthStyle}
                         />
                     </Col>
                 </Row>
                    <br/>
                 <Row>
                     <Col md>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                         <p style={{textAlign: "center"}}>
                             Spending cozy days with plants and pets.
                         </p>
                     </Col>
                     <Col md>
                         <img src={flowers} alt='flowers'
                             style={maxWidthStyle}
                         />
                     </Col>
                 </Row>
            </Container>
        )
    }
}